<template>
  <div>
    <div @click="visible = true">
      <a-button
        v-if="canEdit"
        type="primary"
        style="background: #ff8c18; border: 1px solid #ff8c18"
        icon="plus"
      >
        添加
      </a-button>
    </div>

    <a-modal
      :visible="visible"
      title="添加酒水"
      :footer="null"
      @cancel="visible = false"
    >
      <a-form :form="form" :colon="false" @submit="handleSubmit">
        <a-form-item
          label="类型"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            @change="onTypeChange"
            v-decorator="[
              'typeInv',
              { rules: [{ required: true, message: '请选择！' }] },
            ]"
          >
            <a-select-option
              v-for="item in typeList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          label="子类型"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            @change="onSubTypeChange"
            v-decorator="[
              'typeSub',
              { rules: [{ required: true, message: '请选择！' }] },
            ]"
          >
            <a-select-option
              v-for="item in subTypeList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          label="酒水品名"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            @change="onNameChange"
            v-decorator="[
              'id',
              { rules: [{ required: true, message: '请选择！' }] },
            ]"
          >
            <a-select-option
              v-for="item in list"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          label="库存"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input-number
            :min="0"
            style="width: 100%"
            :disabled="true"
            v-decorator="['quantity']"
          />
        </a-form-item>

        <a-form-item
          label="单位"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select v-decorator="['unit']" :disabled="true">
            <a-select-option
              v-for="item in unitList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          label="单价"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input-number
            :formatter="$inputNumberFormat"
            :disabled="true"
            :min="0"
            style="width: 100%"
            v-decorator="['unitPrice']"
          />
        </a-form-item>

        <a-form-item
          label="申领数量"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input-number
            :min="0"
            style="width: 100%"
            v-decorator="[
              'needQuantity',
              { rules: [{ required: true, message: '请输入！' }] },
            ]"
          />
        </a-form-item>

        <div class="right">
          <a-space>
            <a-button type="primary" html-type="submit">保存</a-button>
            <a-button @click="visible = false">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { fetchList } from "@/api/oa/material";

export default {
  props:{
    canEdit: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      visible: false,
      form: this.$form.createForm(this),
      subTypeList: [], // 子类型列表
      list: [], // 品名列表
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    typeList() {
      return this.findDataDict("invType");
    },
    unitList() {
      return this.findDataDict("invUnit");
    },
  },

  methods: {
    // 选中父分类
    onTypeChange(value) {
      if (value) {
        const obj = this.typeList.find((item) => item.value === value);
        if (obj && Array.isArray(obj.children)) {
          this.subTypeList = obj.children;
        }
      } else {
        this.subTypeList = [];
      }
    },

    // 选中子分类
    onSubTypeChange(value) {
      fetchList({
        typeInv: this.form.getFieldValue("typeInv"),
        typeSub: value,
        pageSize: 999,
      }).then((res) => {
        if (res && Array.isArray(res.list)) {
          this.list = res.list;
          this.form.setFieldsValue({
            id: null,
            quantity: null,
            unit: null,
            unitPrice: null,
          });
        }
      });
    },

    // 选中品名
    onNameChange(value) {
      const obj = this.list.find((item) => item.id === value);
      if (obj) {
        this.form.setFieldsValue({
          quantity: obj.quantity,
          unit: obj.unit,
          unitPrice: obj.unitPrice,
        });
      }
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const { id, quantity, needQuantity } = values;
          if (needQuantity > quantity) {
            this.$message.error("申领数量需小于库存");
            return;
          }

          const obj = this.list.find((item) => item.id === id);

          this.$emit("add", {
            ...values,
            name: obj ? obj.name : "",
          });

          this.visible = false;
          this.form.resetFields();
        }
      });
    },
  },
};
</script>